import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './home.scss'


function Home () {
    const [text, setText] = useState('')

    const fetchMarkdown = () => {
        import(`@data/pages/home.md`)
        .then(res => setText(res.default))
        .catch(err => console.log(err));
    }

    useEffect(fetchMarkdown,[])

    return(
        <div className="contactAbout">
            <h1> About Us </h1>
            <hr/>
            <ReactMarkdown className="contactAboutMD" children={text} skipHtml={true}  remarkPlugins={[remarkGfm]} />
        </div>
    );
}

export default Home;

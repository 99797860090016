import React, { useEffect, useState } from "react";
import jsonGames from '@data/games.json';
import './game-details.scss'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import TrailerVideo from "@components/trailer-video"

function GameDetails() {
    const [text, setText] = useState('')
    const { title } = useParams()
    
    const data = JSON.parse(JSON.stringify(jsonGames));
    let game = data.games.filter(function(game) { return game.title === title; })[0];
    
    const fetchMarkdown = () => {
        import(`../data/games/${game.title}.md`)
        .then(res => setText(res.default))
        .catch(err => console.log(err));
    }

    useEffect(fetchMarkdown,[])
    return(
        <div className="gameDetail">
            <h1>
                { game.title }
            </h1>
            <TrailerVideo embedId={game.trailerEmbedLink}/>
            <ReactMarkdown className="gameDescription" children={text} skipHtml={true}  remarkPlugins={[remarkGfm]} />
        </div>
    );
}
export default GameDetails;
import React from "react";
import { useNavigate } from "react-router-dom";
import './footer.scss'

function Footer() {
    const navigate = useNavigate();

    const navigateToPresskit = () => {
        navigate('/presskit');
      };

    const navigateToPrivacyPolicy = () => {
        navigate('/privacypolicy');
    };
    return(
            <div className="footer">
                <hr/>
                <ul>
                    <li><a href="mailto:contact@thispolo.co.uk" target="_blank">Email</a></li>
                    <li>|</li>
                    <li><a href="https://bsky.app/profile/thispolo.bsky.social" target="_blank">BlueSky</a></li>
                    <li>|</li>
                    <li><a href="https://www.youtube.com/@thispolo-official" target="_blank">YouTube</a></li>
                    <li>|</li>
                    <li><a href="https://discord.gg/mA9tAh5hFR" target="_blank">Discord</a></li>
                    <li>|</li>
                    <li><a onClick={navigateToPrivacyPolicy}>Privacy Policy</a></li>
                    <li>|</li>
                    <li><a onClick={navigateToPresskit}>Press Kit</a></li>
                </ul>
                <p>© ThisPolo LTD, 2021-2024</p>
            </div>
    );
}
export default Footer;
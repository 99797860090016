import PostsimTitleCard from './postsim-title-card.jpg';
import PrintersimTitleCard from './printersim-title-card.jpg';
import ShapeImage from './shape.png'
import FaceImage from './logo_v2.png'
import CreatorImage from './presskit/thispolo-photo.jpg'
import PostsimCover from './postman-cover.png'

export {
    ShapeImage,
    PostsimTitleCard,
    PrintersimTitleCard,
    FaceImage,
    CreatorImage,
    PostsimCover,
};
import './warning-modal.scss'
import React, { useEffect } from "react";
import { useLocalStorage } from "../helpers/local-storage";
import {Modal, Box, Typography, Button} from '@mui/material';
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function WarningModal() {
    const [visited, setVisited] = useLocalStorage("alreadyVisited", false);
    
    useEffect(() => {
        var visited = localStorage.getItem("alreadyVisited");
        if(visited) {
            setVisited(false)
             //do not view Popup
        } else {
             //this is the first time
            setVisited(true)
        }
    }, []);
    
    function handleClose() {
        setVisited(true);
    }

    return(
        <Modal
            open={!visited}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box className="sb6" sx={style}>
                <Typography id="modal-title" variant="h6" component="h2">
                    Greetings  Fellow Brainjuicers
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    Please be aware of any scam websites offering services. THISPOLO LTD are a game development company and do not provide the following services: app design, creation, consultancy, etc. 
                    we only use our website to show you what's in store and to have fun.
                </Typography>
                <Button className="close-button" onClick={handleClose}> Got it! </Button>
            </Box>
        </Modal>
    );
}
export default WarningModal;
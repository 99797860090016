import React, { useState, useEffect } from "react";
import './games.scss'
import jsonGames from '@data/games.json';
import GameBlurb from "@components/games-blurb";

function Games() {
    const [games, setGames] = useState([])

    const fetchGames = () => {
        let data = JSON.parse(JSON.stringify(jsonGames));
        setGames(data.games)
    }

    useEffect(fetchGames,[]);
        
    return(
        <div className="games">
                <h1> Games </h1>
            <hr/>
            <div className="gamesGallery">
                {games.map((game) => {
                    return (<GameBlurb key={game.id} 
                        id = {game.id}
                        title={game.title} 
                        date={game.datePublished}
                        description={game.shortDescription}/>)
                })}
            </div>
        </div>
    );
}
export default Games;
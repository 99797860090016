import './App.scss';
import './common.scss'
import { HashRouter, Routes, Route, Link } from 'react-router-dom';  
import Home from "@pages/home";
import Games from "@pages/games";
import GameDetails from "@pages/game-details";
import PrivacyPolicy from "@pages/privacy-policy";
import NoPage from "@pages/no-page";
import FaceImage from './assets/images/logo_v2.png';
import ShapeImage from './assets/images/shape.png';
import Footer from '@pages/footer';
import PressKit from '@pages/press-kit'; 
import WarningModal from "@components/warning-modal"

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

function App() {
  return (
    <div className='dashboard'>
      <div className='main-page'>
          <HashRouter basename={process.env.PUBLIC_URL}>
          <div className='navigation-bar'>
          <Container className="adorments" sx={{ position: 'absolute' }} >
            <Box 
                display="flex"
              >
              <img className="shape1" src={ShapeImage} alt="Strange Swirl Left" />
              <img className="strangeLogo" src={FaceImage} alt="Strange Logo" />
              <img className="shape2" src={ShapeImage} alt="Strange Swirl Right"/>
            </Box>
          </Container>
          <WarningModal/>
          <Container sx={{ position: 'absolute' }} >
              <Box 
                my={0}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                marginTop="80px"
                p={2}
                zIndex={10}
                sx={{ position: 'relative' }} 
               >
                <nav>
                  <ul>
                      <li><Link to="/">HOME</Link></li>
                      <li>|</li>
                      <li><Link to="/games">GAMES</Link></li>
                      <li>|</li>
                      <li><a href="https://www.tumblr.com/thispolo" target="_blank">BLOG</a></li>
                  </ul>
                </nav>
              </Box>
              <Box
                my={0}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                sx={{ position: 'relative', padding: '4px', top: '-40px' }} 
                p={2}>
                <h1>THISPOLO</h1>
              </Box>
          </Container>
          </div>
            <Routes>
                <Route path="/games"  element={<Games />} />
                <Route path='/games/:title' element={<GameDetails />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />}/>
                <Route path='/presskit' element={<PressKit />}/>
                <Route path="*" element={<NoPage />} />
                <Route index element={<Home />}/>
            </Routes>
            <Footer />
          </HashRouter>
      </div>
    </div>
  );
}

export default App;

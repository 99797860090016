import './trailer-video.scss'
import React, { Component } from "react";

class TrailerVideo extends Component {
    render() {
        return(
            <div className="trailerVideo"> 
            {this.props.embedId !== "" ?
                <div className="video">
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${this.props.embedId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    title="Embedded youtube"
                    /></div> : <div></div>}
            </div>
        );
    }
}
export default TrailerVideo;


<iframe width="560" height="315" src="https://www.youtube.com/embed/fN6OxC3Q0kM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


function PressKit () {
    const [text, setText] = useState('')

    const fetchMarkdown = () => {
        import(`@data/pages/presskit.md`)
        .then(res => setText(res.default))
        .catch(err => console.log(err));
    }

    useEffect(fetchMarkdown,[])

    return(
        <div className="pressKit">
            <h1> Press Kit </h1>
            <hr/>
            <ReactMarkdown className="pressKitMD" children={text} skipHtml={true}  remarkPlugins={[remarkGfm]} />
        </div>
    );
}

export default PressKit;

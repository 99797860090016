import './games-blurb.scss'
import React, { Component } from "react";
import { Link } from 'react-router-dom'; 

class GameBlurb extends Component {
    render() {
        const images = require.context('../../assets/images', true);
        const gameImage = images(`./${this.props.title.toLowerCase()}-title-card.jpg`).default;
        
        return(
                <div className="gameBlurb" style={{ backgroundImage: `url(${gameImage})`, backgroundSize: '300px'}}> 
                    <Link className='blurbOverlay' to={`/games/${this.props.title}`}>
                        <p>{this.props.title}</p>
                    </Link>
                </div>
        );
    }
}
export default GameBlurb;
import React, { Component } from "react";

class NoPage extends Component {
    render() {
        return(
            <div>
                <h1>
                    NoPage
                </h1>
            </div>
        );
    }
}
export default NoPage;  